import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Box,
  Typography,
  Fade,
  FormControl,
  MenuItem,
  Slider,
  InputLabel,
  Select,
  IconButton,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Checkbox,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { subWeeks } from 'date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import LayersClearRoundedIcon from '@material-ui/icons/LayersClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_VALUE } from '../inputs/controlled-select.component';
import { getSubrowName } from '../../utils/audits.utils';

const useStyles = makeStyles((theme) => ({
  fade: {
    maskImage: [
      'linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)',
      'linear-gradient(to top, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%)'
    ]
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'scroll',
    maxHeight: '1rem'
  },
  white: {
    color: theme.palette.inverted.main
  },
  clearFilter: {
    color: '#28EAD2',
    borderColor: '#28EAD2',
    '&:hover': {
      color: '#28EAD2',
      borderColor: '#28EAD2'
    }
  }
}));

const MenuProps = {
  variant: 'menu',
  getContentAnchorEl: null
};

const AuditSort = observer(() => {
  const DEFAULT_SELECT_VALUES = {
    SUBSECTION: 'No selection',
    ROBOT: 'All robots'
  };

  const DEVIATION_RANGE_VALUES = {
    AVG_MIN: 0,
    AVG_MAX: 150,
    MAX_MIN: 0,
    MAX_MAX: 150
  };

  const classes = useStyles();
  const {
    autonomyAuditReportsStore,
    autonomyRobotStore,
    countriesStore,
    regionsStore,
    chaperonePropertyStore,
    sectionStore,
    subsectionStore,
    blocksStore,
    subBlocksStore,
    subrowStore
  } = useStores();
  const [selectedStartTime, setSelectedStartTime] = useState(subWeeks(new Date(), 2).getTime());
  const [selectedEndTime, setSelectedEndTime] = useState(new Date().getTime());
  const [selectedCountry, setSelectedCountry] = useState(DEFAULT_VALUE);
  const [selectedRegion, setSelectedRegion] = useState(DEFAULT_VALUE);
  const [selectedProperty, setSelectedProperty] = useState(DEFAULT_VALUE);
  const [selectedSection, setSelectedSection] = useState(DEFAULT_VALUE);
  const [selectedBlock, setSelectedBlock] = useState(DEFAULT_VALUE);
  const [selectedSubBlock, setSelectedSubBlock] = useState(DEFAULT_VALUE);
  const [selectedSubsections, setSelectedSubsections] = useState([DEFAULT_SELECT_VALUES.SUBSECTION]);
  const [selectedUseCase, setSelectedUseCase] = useState(DEFAULT_VALUE);
  const [selectedRobots, setSelectedRobots] = useState([DEFAULT_SELECT_VALUES.ROBOT]);
  const [selectedAvgDeviationRange, setSelectedAvgDeviationRange] = useState([
    DEVIATION_RANGE_VALUES.AVG_MIN,
    DEVIATION_RANGE_VALUES.AVG_MAX
  ]);
  const [selectedMaxDeviationRange, setSelectedMaxDeviationRange] = useState([
    DEVIATION_RANGE_VALUES.MAX_MIN,
    DEVIATION_RANGE_VALUES.MAX_MAX
  ]);
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT_VALUE);
  const [isSectionSolar, setIsSectionSolar] = useState(true);

  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [regionSelectorOpen, setRegionSelectorOpen] = useState(false);
  const [propertySelectorOpen, setPropertySelectorOpen] = useState(false);
  const [sectionSelectorOpen, setSectionSelectorOpen] = useState(false);
  const [blockSelectorOpen, setBlockSelectorOpen] = useState(false);
  const [subBlockSelectorOpen, setSubBlockSelectorOpen] = useState(false);
  const [subsectionSelectorOpen, setSubsectionSelectorOpen] = useState(false);

  const [areFiltersCleared, setAreFiltersCleared] = useState(false);
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const sliderValueText = (value) => `${value}cm`;

  const getRecordsOnPageLoad = async () => {
    autonomyAuditReportsStore.setLoading(true);
    await autonomyRobotStore.getRobots();

    autonomyAuditReportsStore.setFilter({
      startTime: subWeeks(new Date(), 2).getTime(),
      endTime: new Date().getTime()
    });
    autonomyAuditReportsStore.getRecords();
  };

  useEffect(() => {
    countriesStore.getCountries();
    getRecordsOnPageLoad();
  }, []);

  useEffect(() => {
    if (selectedStartTime > selectedEndTime) setIsTimeInvalid(true);
    else setIsTimeInvalid(false);
  }, [selectedStartTime, selectedEndTime]);

  useEffect(() => {
    setSelectedRegion(DEFAULT_VALUE);
    setSelectedProperty(DEFAULT_VALUE);
    setSelectedSection(DEFAULT_VALUE);
    setSelectedBlock(DEFAULT_VALUE);
    setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
    if (selectedCountry && selectedCountry !== DEFAULT_VALUE) {
      regionsStore.clearRegions();
      setSelectedRegion(DEFAULT_VALUE);
      setSelectedProperty(DEFAULT_VALUE);
      chaperonePropertyStore.clearProperties();
      regionsStore.getRegions(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedProperty(DEFAULT_VALUE);
    setSelectedSection(DEFAULT_VALUE);
    setSelectedBlock(DEFAULT_VALUE);
    setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
    if (selectedRegion && selectedRegion !== DEFAULT_VALUE) chaperonePropertyStore.getProperties(selectedRegion);
  }, [regionsStore, selectedRegion]);

  useEffect(() => {
    setSelectedSection(DEFAULT_VALUE);
    setSelectedBlock(DEFAULT_VALUE);
    setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
    if (selectedProperty && selectedProperty !== DEFAULT_VALUE) {
      if (selectedUseCase === 'true') {
        blocksStore.getBlocks(selectedProperty);
      } else {
        sectionStore.getSections(selectedRegion, selectedProperty);
      }
    }
  }, [selectedProperty]);

  useEffect(() => {
    setSelectedSubBlock(DEFAULT_VALUE);
    setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
    if (selectedBlock && selectedBlock !== DEFAULT_VALUE) {
      // if (selectedUseCase === 'true') {
      subBlocksStore.getSubBlocks(selectedBlock);
      // }
    }
  }, [selectedBlock]);

  useEffect(() => {
    const handleSolarOrSnow = async () => {
      // const result = await sectionStore.getSectionById({ id: selectedSection });
      // const { isSolar } = result.results;

      if (selectedUseCase === 'true') {
        setIsSectionSolar(true);
        subrowStore.getSubrows(selectedRegion, selectedProperty, selectedSubBlock);
        setSelectedUseCase(true);
      } else {
        setIsSectionSolar(false);
        subsectionStore.getSubsections(selectedCountry, selectedRegion, selectedProperty, selectedSection);
        setSelectedUseCase(false);
      }
    };

    setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
    if (selectedSection && selectedSection !== DEFAULT_VALUE) handleSolarOrSnow();
    if (selectedSubBlock && selectedSubBlock !== DEFAULT_VALUE) handleSolarOrSnow();
    else setSelectedUseCase(DEFAULT_VALUE);
  }, [selectedSection, selectedSubBlock]);

  const compare = (a, b) => {
    const firstSerialNumber = a.serial_number.toLowerCase();
    const secondSerialNumber = b.serial_number.toLowerCase();
    if (firstSerialNumber < secondSerialNumber) {
      return -1;
    }
    if (firstSerialNumber > secondSerialNumber) {
      return 1;
    }
    return 0;
  };

  const handleChangeCountry = (event) => {
    if (event.target.value) setSelectedCountry(event.target.value);
    if (event.target.value !== DEFAULT_VALUE) setRegionSelectorOpen(true);
  };

  const handleChangeRegion = (event) => {
    if (event.target.value) setSelectedRegion(event.target.value);
    if (event.target.value !== DEFAULT_VALUE) setPropertySelectorOpen(true);
  };

  const handleChangeProperty = (event) => {
    if (event.target.value) setSelectedProperty(event.target.value);
    if (event.target.value !== DEFAULT_VALUE && selectedUseCase === 'true') {
      setBlockSelectorOpen(true);
    } else setSectionSelectorOpen(true);
  };

  const handleChangeSection = (event) => {
    if (event.target.value) setSelectedSection(event.target.value);
    if (event.target.value !== DEFAULT_VALUE) setSubsectionSelectorOpen(true);
  };

  const handleChangeBlock = (event) => {
    if (event.target.value) setSelectedBlock(event.target.value);
    if (event.target.value !== DEFAULT_VALUE) setSubBlockSelectorOpen(true);
  };

  const handleChangeSubBlock = (event) => {
    if (event.target.value) setSelectedSubBlock(event.target.value);
    if (event.target.value !== DEFAULT_VALUE) setSubsectionSelectorOpen(true);
  };

  const handleChangeSubsections = (event) => {
    setSelectedSubsections(event.target.value.filter((e) => e !== DEFAULT_SELECT_VALUES.SUBSECTION));
    if (event.target.value.length === 0) setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
  };

  const handleChangeUseCases = (event) => {
    setSelectedUseCase(event.target.value);
  };

  const handleChangeRobots = (event) => {
    setSelectedRobots(event.target.value.filter((e) => e !== DEFAULT_SELECT_VALUES.ROBOT));
    if (event.target.value.length === 0) setSelectedRobots([DEFAULT_SELECT_VALUES.ROBOT]);
  };

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleChangeAvgDeviation = (event, newValue) => {
    setSelectedAvgDeviationRange(newValue);
  };

  const handleChangeMaxDeviation = (event, newValue) => {
    setSelectedMaxDeviationRange(newValue);
  };

  const subsectionNamesToIds = async () => {
    const selectedSubsectionIds = [];
    selectedSubsections.forEach((subsectionName) => {
      if (isSectionSolar) selectedSubsectionIds.push(subrowStore.getByName(subsectionName)?.id);
      else selectedSubsectionIds.push(subsectionStore.getByName(subsectionName)?.id);
    });
    return selectedSubsectionIds;
  };

  const handleSearch = async () => {
    if (isTimeInvalid) return;

    const subsectionIds = await subsectionNamesToIds();

    autonomyAuditReportsStore.setFilter({
      // Can't use null on the control itself, so swap it out here
      countryId: selectedCountry === DEFAULT_VALUE ? null : selectedCountry,
      regionId: selectedRegion === DEFAULT_VALUE ? null : selectedRegion,
      propertyId: selectedProperty === DEFAULT_VALUE ? null : selectedProperty,
      sectionId: selectedSection === DEFAULT_VALUE ? null : selectedSection,
      blockId: selectedBlock === DEFAULT_VALUE ? null : selectedBlock,
      subblockId: selectedSubBlock === DEFAULT_VALUE ? null : selectedSubBlock,
      subsectionIds: selectedSubsections[0] === DEFAULT_SELECT_VALUES.SUBSECTION ? null : subsectionIds,
      completedSuccessfully: selectedStatus === DEFAULT_VALUE ? null : selectedStatus === 'true',
      robots: selectedRobots[0] === DEFAULT_SELECT_VALUES.ROBOT ? null : selectedRobots,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      averageDeviationStart:
        selectedAvgDeviationRange[0] === DEVIATION_RANGE_VALUES.AVG_MIN && selectedAvgDeviationRange[1] === DEVIATION_RANGE_VALUES.AVG_MAX
          ? null
          : selectedAvgDeviationRange[0] / 100,
      averageDeviationEnd: selectedAvgDeviationRange[1] === DEVIATION_RANGE_VALUES.AVG_MAX ? null : selectedAvgDeviationRange[1] / 100,
      maxDeviationStart:
        selectedMaxDeviationRange[0] === DEVIATION_RANGE_VALUES.MAX_MIN && selectedMaxDeviationRange[1] === DEVIATION_RANGE_VALUES.MAX_MAX
          ? null
          : selectedMaxDeviationRange[0] / 100,
      maxDeviationEnd: selectedMaxDeviationRange[1] === DEVIATION_RANGE_VALUES.MAX_MAX ? null : selectedMaxDeviationRange[1] / 100,
      useCase: selectedUseCase === DEFAULT_VALUE ? null : selectedUseCase === 'true'
    });

    autonomyAuditReportsStore.getRecords();
  };

  const handleClearAllFilters = () => {
    if (!areFiltersCleared) {
      setSelectedStartTime(0);
      setSelectedEndTime(new Date().getTime());
      setSelectedCountry(DEFAULT_VALUE);
      setSelectedRegion(DEFAULT_VALUE);
      setSelectedProperty(DEFAULT_VALUE);
      setSelectedSection(DEFAULT_VALUE);
      setSelectedBlock(DEFAULT_VALUE);
      setSelectedSubBlock(DEFAULT_VALUE);
      setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION]);
      setSelectedUseCase(DEFAULT_VALUE);
      setSelectedRobots([DEFAULT_SELECT_VALUES.ROBOT]);
      setSelectedAvgDeviationRange([DEVIATION_RANGE_VALUES.AVG_MIN, DEVIATION_RANGE_VALUES.AVG_MAX]);
      setSelectedMaxDeviationRange([DEVIATION_RANGE_VALUES.MAX_MIN, DEVIATION_RANGE_VALUES.MAX_MAX]);
      setSelectedStatus(DEFAULT_VALUE);
      setAreFiltersCleared(true);
    }
    setTimeout(() => setAreFiltersCleared(false), 3000);
  };

  return (
    <Fade in>
      <Accordion
        expanded={autonomyAuditReportsStore.showFilter}
        onChange={() => autonomyAuditReportsStore.setShowFilter(!autonomyAuditReportsStore.showFilter)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Typography align="center" variant="h5">
            Filter
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} direction="row" justifyContent="space-between" alignContent="center">
            {/* Start Time */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={10} sm={11} lg={11} xl={10}>
                  <KeyboardDateTimePicker
                    inputVariant="outlined"
                    id="start-time-picker-dialog"
                    label="Start time"
                    value={selectedStartTime}
                    onChange={(date) => setSelectedStartTime(new Date(date).getTime())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    fullWidth
                    error={isTimeInvalid}
                  />
                </Grid>
                <Grid item xs={2} sm={1} lg={1} xl={2}>
                  <Box ml={isLarge ? 0 : 1}>
                    <Tooltip title={<Typography>Clear start time</Typography>}>
                      <span>
                        <IconButton aria-label="clear-start-time" size="small" onClick={() => setSelectedStartTime(0)}>
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            {/* Property */}
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="property-select-label">Property</InputLabel>
                <Select
                  open={propertySelectorOpen}
                  onOpen={() => setPropertySelectorOpen(true)}
                  onClose={() => setPropertySelectorOpen(false)}
                  MenuProps={MenuProps}
                  variant="outlined"
                  label="Property"
                  displayEmpty
                  labelId="property-select-label"
                  value={selectedProperty}
                  onChange={handleChangeProperty}
                  disabled={selectedRegion === DEFAULT_VALUE}
                >
                  <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                  {chaperonePropertyStore.properties.length ? (
                    chaperonePropertyStore.properties.map((property) => (
                      <MenuItem key={property.id} value={property.id}>
                        {property.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No properties</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/* End Time */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={10} sm={11} lg={11} xl={10}>
                  <KeyboardDateTimePicker
                    inputVariant="outlined"
                    id="end-time-picker-dialog"
                    label="End time"
                    value={selectedEndTime}
                    onChange={(date) => setSelectedEndTime(new Date(date).getTime())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    fullWidth
                    error={isTimeInvalid}
                  />
                </Grid>
                <Grid item xs={2} sm={1} lg={1} xl={2}>
                  <Box ml={isLarge ? 0 : 1}>
                    <Tooltip title={<Typography>Clear end time</Typography>}>
                      <span>
                        <IconButton aria-label="clear-end-time" size="small" onClick={() => setSelectedEndTime(new Date().getTime())}>
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            {/* Section Or Block */}
            <Grid item xs={6}>
              {selectedUseCase === 'true' ? (
                <div>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="block-select-label">Block</InputLabel>
                    <Select
                      open={blockSelectorOpen}
                      onOpen={() => setBlockSelectorOpen(true)}
                      onClose={() => setBlockSelectorOpen(false)}
                      MenuProps={MenuProps}
                      variant="outlined"
                      label="Block"
                      displayEmpty
                      labelId="block-select-label"
                      value={selectedBlock}
                      onChange={handleChangeBlock}
                      disabled={selectedProperty === DEFAULT_VALUE}
                    >
                      <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                      {blocksStore.blocks.length ? (
                        blocksStore.blocks.map((block) => (
                          <MenuItem key={block.id} value={block.id}>
                            {block.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No blocks</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <FormControl style={{ marginTop: '15px' }} variant="outlined" fullWidth>
                    <InputLabel id="block-select-label">Sub Block</InputLabel>
                    <Select
                      open={subBlockSelectorOpen}
                      onOpen={() => setSubBlockSelectorOpen(true)}
                      onClose={() => setSubBlockSelectorOpen(false)}
                      MenuProps={MenuProps}
                      variant="outlined"
                      label="Sub Block"
                      displayEmpty
                      labelId="SUB-block-select-label"
                      value={selectedSubBlock}
                      onChange={handleChangeSubBlock}
                      disabled={selectedProperty === DEFAULT_VALUE}
                    >
                      <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                      {subBlocksStore.subBlocks.length ? (
                        subBlocksStore.subBlocks.map((subBlock) => (
                          <MenuItem key={subBlock.id} value={subBlock.id}>
                            {subBlock.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No blocks</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="section-select-label">Section</InputLabel>
                  <Select
                    open={sectionSelectorOpen}
                    onOpen={() => setSectionSelectorOpen(true)}
                    onClose={() => setSectionSelectorOpen(false)}
                    MenuProps={MenuProps}
                    variant="outlined"
                    label="Section"
                    displayEmpty
                    labelId="section-select-label"
                    value={selectedSection}
                    onChange={handleChangeSection}
                    disabled={selectedProperty === DEFAULT_VALUE}
                  >
                    <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                    {sectionStore.sections.length ? (
                      sectionStore.sections.map((section) => (
                        <MenuItem key={section.id} value={section.id}>
                          {section.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No sections</MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {/* Use Case */}
            <Grid item xs={6}>
              {/* <Tooltip
                disableHoverListener={selectedSection === DEFAULT_VALUE}
                disableFocusListener={selectedSection === DEFAULT_VALUE}
                disableTouchListener={selectedSection === DEFAULT_VALUE}
                title={<Typography align="center">Use casee disabled when section is selected.</Typography>}
              >
                <span> */}
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="use-case-select-label">Use case</InputLabel>
                <Select
                  MenuProps={MenuProps}
                  variant="outlined"
                  label="Use case"
                  displayEmpty
                  labelId="use-case-select-label"
                  value={selectedUseCase}
                  onChange={handleChangeUseCases}
                  disabled={selectedSection !== DEFAULT_VALUE}
                >
                  <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                  <MenuItem value="true">Solar</MenuItem>
                  <MenuItem value="false">Non-solar</MenuItem>
                </Select>
              </FormControl>
              {/* </span>
              </Tooltip> */}
            </Grid>
            {/* Subrows/Subsections */}
            <Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={10} sm={11} lg={11} xl={10}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="use-cases-select-label">{isSectionSolar ? 'Subrows' : 'Subsections'}</InputLabel>
                  <Select
                    open={subsectionSelectorOpen}
                    onOpen={() => setSubsectionSelectorOpen(true)}
                    onClose={() => setSubsectionSelectorOpen(false)}
                    MenuProps={MenuProps}
                    variant="outlined"
                    label={isSectionSolar ? 'Subrows' : 'Subsections'}
                    multiple
                    displayEmpty
                    id="subsections-select"
                    labelId="subsections-select-label"
                    value={selectedSubsections}
                    onChange={handleChangeSubsections}
                    disabled={(selectedUseCase === 'true' && selectedSection === DEFAULT_VALUE) || selectedSubBlock === DEFAULT_VALUE}
                    renderValue={(selected) => {
                      // Changes render method depending on snow/solar
                      if (selected) {
                        if (isSectionSolar)
                          return selected
                            .map((e) => (e === DEFAULT_SELECT_VALUES.SUBSECTION ? DEFAULT_SELECT_VALUES.SUBSECTION : getSubrowName(e)))
                            .join(', ');
                        return selected.join(', ');
                      }
                    }}
                  >
                    {isSectionSolar ? (
                      subrowStore.subrows.length ? (
                        subrowStore.subrows.map((subsection) => (
                          <MenuItem key={subsection.id} value={subsection.name}>
                            <Checkbox disableRipple checked={selectedSubsections.indexOf(subsection.name) > -1} />
                            <ListItemText primary={getSubrowName(subsection.name)} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No subrows</MenuItem>
                      )
                    ) : subsectionStore.subsections.length ? (
                      subsectionStore.subsections.map((subsection) => (
                        <MenuItem key={subsection.id} value={subsection.name}>
                          <Checkbox disableRipple checked={selectedSubsections.indexOf(subsection.name) > -1} />
                          <ListItemText primary={subsection.name} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No subsections</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={1} lg={1} xl={2}>
                <Box ml={isLarge ? 0 : 1}>
                  <Tooltip title={<Typography>{`Clear ${isSectionSolar ? 'subrows' : 'subsections'}`}</Typography>}>
                    <span>
                      <IconButton
                        aria-label="clear-subsections"
                        size="small"
                        onClick={() => setSelectedSubsections([DEFAULT_SELECT_VALUES.SUBSECTION])}
                      >
                        <ClearIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            {/* Country */}
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="country-select-label">Country</InputLabel>
                <Select
                  open={countrySelectorOpen}
                  onOpen={() => setCountrySelectorOpen(true)}
                  onClose={() => setCountrySelectorOpen(false)}
                  MenuProps={MenuProps}
                  variant="outlined"
                  label="Country"
                  displayEmpty
                  labelId="country-select-label"
                  value={selectedCountry}
                  disabled={selectedUseCase === DEFAULT_VALUE}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                  {countriesStore.countries.length ? (
                    countriesStore.countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No countries</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/* Robots */}
            <Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={10} sm={11} lg={11} xl={10}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="robots-select-label">Robots</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    variant="outlined"
                    label="Robots"
                    multiple
                    displayEmpty
                    labelId="robots-select-label"
                    id="robots-select"
                    value={selectedRobots}
                    onChange={handleChangeRobots}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {autonomyRobotStore.robots.length ? (
                      autonomyRobotStore.robots
                        .slice()
                        .sort(compare)
                        .map((robot) => (
                          <MenuItem key={robot.serial_number} value={robot.serial_number}>
                            <Checkbox checked={selectedRobots.indexOf(robot.serial_number) > -1} />
                            <ListItemText primary={robot.serial_number} />
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem disabled>No robots</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={1} lg={1} xl={2}>
                <Box ml={isLarge ? 0 : 1}>
                  <Tooltip title={<Typography>Clear robots</Typography>}>
                    <span>
                      <IconButton aria-label="clear-robots" size="small" onClick={() => setSelectedRobots([DEFAULT_SELECT_VALUES.ROBOT])}>
                        <ClearIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            {/* Region */}
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select
                  open={regionSelectorOpen}
                  onOpen={() => setRegionSelectorOpen(true)}
                  onClose={() => setRegionSelectorOpen(false)}
                  MenuProps={MenuProps}
                  variant="outlined"
                  label="Region"
                  displayEmpty
                  labelId="region-select-label"
                  value={selectedRegion}
                  onChange={handleChangeRegion}
                  disabled={selectedCountry === DEFAULT_VALUE}
                >
                  <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                  {regionsStore.regions.length ? (
                    regionsStore.regions.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No regions</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  MenuProps={MenuProps}
                  variant="outlined"
                  label="Status"
                  displayEmpty
                  labelId="status-select-label"
                  value={selectedStatus}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={DEFAULT_VALUE}>No selection</MenuItem>
                  <MenuItem value="true">Complete</MenuItem>
                  <MenuItem value="false">Incomplete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Bottom-center */}
            {/* Average Deviation */}
            <Grid item xs={6}>
              <Grid item xs={12} container justifyContent="center">
                <Typography id="average-deviation-range-slider" align="center" gutterBottom>
                  Average deviation
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignContent="center">
                <Grid item xs={10}>
                  <Slider
                    value={selectedAvgDeviationRange}
                    onChange={handleChangeAvgDeviation}
                    valueLabelDisplay="auto"
                    aria-labelledby="average-deviation-range-slider"
                    valueLabelFormat={sliderValueText}
                    min={DEVIATION_RANGE_VALUES.AVG_MIN}
                    max={DEVIATION_RANGE_VALUES.AVG_MAX}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box ml={isLarge ? 1 : 2}>
                    <Tooltip title={<Typography>Reset average deviation</Typography>}>
                      <span>
                        <IconButton
                          aria-label="clear-average-deviation-range"
                          size="small"
                          onClick={() => setSelectedAvgDeviationRange([DEVIATION_RANGE_VALUES.AVG_MIN, DEVIATION_RANGE_VALUES.AVG_MAX])}
                        >
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* Maximum Deviation */}
            <Grid item xs={6}>
              <Grid item xs={12} container justifyContent="center">
                <Typography id="maximum-deviation-range-slider" align="center" gutterBottom>
                  Maximum deviation
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="center" alignContent="center">
                <Grid item xs={10}>
                  <Slider
                    value={selectedMaxDeviationRange}
                    onChange={handleChangeMaxDeviation}
                    valueLabelDisplay="auto"
                    aria-labelledby="maximum-deviation-range-slider"
                    valueLabelFormat={sliderValueText}
                    min={DEVIATION_RANGE_VALUES.MAX_MIN}
                    max={DEVIATION_RANGE_VALUES.MAX_MAX}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box ml={isLarge ? 1 : 2}>
                    <Tooltip title={<Typography>Reset maximum deviation</Typography>}>
                      <span>
                        <IconButton
                          aria-label="clear-maximum-deviation-range"
                          size="small"
                          onClick={() => setSelectedMaxDeviationRange([DEVIATION_RANGE_VALUES.MAX_MIN, DEVIATION_RANGE_VALUES.MAX_MAX])}
                        >
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              {/* Clear All Filters */}
              <Grid item xs={3} md={2}>
                <Tooltip title={<Typography>Clear all filters</Typography>}>
                  <span>
                    <Button
                      variant="outlined"
                      size="large"
                      type="submit"
                      onClick={handleClearAllFilters}
                      color="primary"
                      disabled={autonomyAuditReportsStore.loading}
                      className={areFiltersCleared ? classes.clearFilter : null}
                      fullWidth
                    >
                      {areFiltersCleared ? <CheckRoundedIcon /> : <LayersClearRoundedIcon />}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              {/* Search */}
              <Grid item xs={9} md={10}>
                <Tooltip title={<Typography>Search</Typography>}>
                  <span>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleSearch}
                      color="secondary"
                      disabled={autonomyAuditReportsStore.loading || isTimeInvalid}
                      fullWidth
                    >
                      {autonomyAuditReportsStore.loading ? (
                        <CircularProgress size="1.5rem" className={classes.white} />
                      ) : isTimeInvalid ? (
                        <Typography className={classes.white}>Invalid time</Typography>
                      ) : (
                        <SearchRoundedIcon />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fade>
  );
});

export default AuditSort;
