import React from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade, Tooltip, Icon } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_NO_DATA, celsiusToFahrenheit, getVersionNumber } from '../../utils/ui.utils';
import { GIT_STATUS, ICON_TYPES } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));

const StateInfo = ({ title, infoList }) => (
  <Grid item xs={6}>
    <Box mb={1}>
      <Typography align="center" variant="h6">
        <b>{title}</b>
      </Typography>
    </Box>
    {infoList.map((info) =>
      info.label !== '' ? (
        <React.Fragment key={info.label}>
          <Typography align="center" variant="subtitle1">
            {info.label}: <b>{info.value !== null ? info.value : DEFAULT_NO_DATA}</b>
            {info.tooltipTitle && (
              <Tooltip title={info.tooltipTitle}>
                <span
                  style={{
                    position: 'relative',
                    top: '4px',
                    right: '-5px',
                    color: (info.value && info.iconType === ICON_TYPES.WARNING && '#eed202') || (info.value && 'red') || '#414042'
                  }}
                >
                  {!info.Operational && info.iconType !== '' && <Icon component={WarningOutlinedIcon} />}
                </span>
              </Tooltip>
            )}
          </Typography>
        </React.Fragment>
      ) : (
        <div></div>
      )
    )}
  </Grid>
);

export default function SwapbotDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();
  const name = autonomyRobotStore.getSelectedRobot()?.name;
  const serialNumber = autonomyRobotStore.getSelectedRobot()?.serial_number;
  const version = getVersionNumber(serialNumber);
  const showLiquidSensors = version !== 'sim' && parseInt(version) >= 75;

  const stableLocalization = autonomyRobotStore.getSelectedRobot()?.robot_state?.location_state?.is_localization_stable;
  let stableLocalizationValue = null;

  const baseFanIsOn = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.base_fan_is_on;
  const oilFanIsOn = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_fan_is_on;
  let oilFanStateValue = null;
  let baseFanStateValue = null;

  const baseLiquidSensor = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.base_liquid_sensor;
  let baseLiquidSensorValue = null;

  const tankLiquidSensor = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tank_liquid_sensor;
  let tankLiquidSensorValue = null;

  const hoseCoolingModeState = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.cooling_mode_enabled;
  let hoseCoolingModeStateValue = null;

  const gitStatus = GIT_STATUS[autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.git_update_state];

  const hwEstopEngaged = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.estop_is_on;
  const sensingEdgeEngaged = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sensing_edge_is_on;
  const swEstopEngaged = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sw_estop_is_on;
  const isRobotPhyicallyStopped = hwEstopEngaged || sensingEdgeEngaged || swEstopEngaged;

  if (stableLocalization !== null) {
    if (stableLocalization) {
      stableLocalizationValue = 'Calibrated';
    } else {
      if (autonomyRobotStore.getSelectedRobot()?.robot_state?.location_state?.gps_fix_status === 'Un-healthy') {
        stableLocalizationValue = 'BAD GPS Signal';
      } else {
        stableLocalizationValue = 'Drive more to calibrate';
      }
    }
  }

  if (baseFanIsOn !== null) {
    baseFanStateValue = baseFanIsOn ? 'ON' : 'OFF';
  }

  if (oilFanIsOn !== null) {
    oilFanStateValue = oilFanIsOn ? 'ON' : 'OFF';
  }

  if (hoseCoolingModeState !== undefined && hoseCoolingModeState !== null) {
    hoseCoolingModeStateValue =
      !isRobotPhyicallyStopped &&
      hoseCoolingModeState &&
      autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.motors[2]?.rpm > 0
        ? 'ON'
        : 'OFF';
  } else {
    hoseCoolingModeStateValue = DEFAULT_NO_DATA;
  }

  if (showLiquidSensors && baseLiquidSensor !== undefined && baseLiquidSensor !== null) {
    baseLiquidSensorValue = baseLiquidSensor ? 'NOT LEAKING' : 'LEAKING';
  } else {
    baseLiquidSensorValue = DEFAULT_NO_DATA;
  }

  if (showLiquidSensors && tankLiquidSensor !== undefined && tankLiquidSensor !== null) {
    tankLiquidSensorValue = tankLiquidSensor ? 'LEAKING' : 'NOT LEAKING';
  } else {
    tankLiquidSensorValue = DEFAULT_NO_DATA;
  }

  const robotStateInfoList = [
    {
      label: 'GPS State',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.location_state?.gps_fix_status || DEFAULT_NO_DATA,
      tooltipTitle: `The GPS of ${name} is non-operational. Try to restart the software first then power-cycle the robot then contact autonomy if the issue persists.`,
      Operational: autonomyRobotStore.getSelectedRobot()?.robot_state?.location_state?.gps_fix_status === 'Fixed RTK',
      iconType: ICON_TYPES.PROBLEM
    },
    {
      label: 'INS State',
      value: stableLocalizationValue,
      tooltipTitle: `The ${name} must be driven around to calibrate the INS.`,
      Operational: stableLocalizationValue === 'Calibrated',
      iconType: ICON_TYPES.PROBLEM
    },
    {
      label: 'Control Mode',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.control_mode
    },
    {
      label: 'Base Fans',
      tooltipTitle: 'Fans have to be running now',
      Operational: baseFanStateValue === 'ON',
      iconType:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.motors[0]?.motor_temp >= 80
          ? ICON_TYPES.PROBLEM
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.motors[0]?.motor_temp > 60
            ? ICON_TYPES.WARNING
            : '',
      value: baseFanStateValue
    },
    {
      label: 'Hydraulics Oil Temp',
      tooltipTitle: 'Hydraulics Oil is too hot to keep it operating',
      Operational: autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature <= 60,
      iconType:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 70
          ? ICON_TYPES.PROBLEM
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature > 60
            ? ICON_TYPES.WARNING
            : '',
      value: `${
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature !== null
          ? autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature
          : DEFAULT_NO_DATA
      }°C | ${
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature !== null
          ? celsiusToFahrenheit(autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature)
          : DEFAULT_NO_DATA
      }°F`
    },
    {
      label: 'Hydraulics Oil Fans',
      tooltipTitle: 'Fans have to be running now',
      Operational: oilFanStateValue === 'ON',
      iconType:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 68
          ? ICON_TYPES.PROBLEM
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 60
            ? ICON_TYPES.WARNING
            : '',
      value: oilFanStateValue
    },
    {
      label: 'Hydraulics Oil Hose Cooling',
      tooltipTitle:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 68
          ? 'Stop Ops, Hose cooling has to be running now'
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 60
            ? 'Continue Ops, Hose cooling will need to be running soon'
            : '',
      Operational: hoseCoolingModeStateValue === 'ON',
      iconType:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 68
          ? ICON_TYPES.PROBLEM
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.oil_temperature >= 60
            ? ICON_TYPES.WARNING
            : '',
      value: hoseCoolingModeStateValue
    },
    {
      label: 'Base Liquid Sensor',
      value: baseLiquidSensorValue,
      tooltipTitle: 'The base has liquid in it. Please contact Service.',
      Operational: baseLiquidSensorValue !== 'LEAKING',
      iconType: ICON_TYPES.PROBLEM
    },
    {
      label: 'Tank Liquid Sensor',
      value: tankLiquidSensorValue,
      tooltipTitle: 'The tank is leaking. Please contact Service.',
      Operational: tankLiquidSensorValue !== 'LEAKING',
      iconType: ICON_TYPES.PROBLEM
    }
  ];

  const deploymentStateInfoList = [
    {
      label: 'Status',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_status || DEFAULT_NO_DATA,
      tooltipTitle: 'Image is being pulled',
      Operational: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_status === 'running',
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Deployment Target',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_target || DEFAULT_NO_DATA
    },
    {
      label: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_target !== 'dev' ? '' : 'Branch Deployed',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.branch_deployed || DEFAULT_NO_DATA
    },
    {
      label: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version === '' ? 'Commit' : 'Version',
      value:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version === ''
          ? autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.commit || DEFAULT_NO_DATA
          : autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version || DEFAULT_NO_DATA,
      Operational:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version !== '' &&
        autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version.includes('latest'),
      iconType: ICON_TYPES.WARNING
    },
    {
      label: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_target !== 'dev' ? '' : 'Git Status',
      value: gitStatus || DEFAULT_NO_DATA,
      tooltipTitle:
        gitStatus === GIT_STATUS[1]
          ? `${name} has outdated software deployed and should be updated as soon as can be done safely.`
          : `${name} has experimental software deployed and should not be operated without the explicit permission of the autonomy team.`,
      Operational: gitStatus === GIT_STATUS[0],
      iconType: ICON_TYPES.WARNING
    },
    {
      label: 'Current Attachment',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.tool_type || DEFAULT_NO_DATA
    },
    {
      label: 'Free Storage',
      value: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.free_storage
        ? `${autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.free_storage}%`
        : DEFAULT_NO_DATA,
      tooltipTitle: 'The robot is low on storage, please click the Free Up Storage button.',
      Operational: autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.free_storage > 20,
      iconType:
        autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.free_storage <= 10 ? ICON_TYPES.PROBLEM : ICON_TYPES.WARNING
    }
  ];

  return (
    <Fade in>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="row" justifyContent="center" alignContent="center" spacing={2}>
          <StateInfo title="Robot State" infoList={robotStateInfoList} />
          <StateInfo title="Deployment State" infoList={deploymentStateInfoList} />
        </Grid>
      </Paper>
    </Fade>
  );
}
