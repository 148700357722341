import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { BATTERIES_WARNING_LEVELS } from '../../utils/constants';
const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warningIcon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.warning.main,
    width: '100%'
  },
  errorIcon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main
  },
  centeredIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

/**
 * Displays warning when battery reaches low or critical level.
 * Low: When battery is within 11-15% range, a warning will pop up prompting user to drive robot to a chargin station.
 * User needs to confirm the prompt to gain control of robot back.
 * Critical: When battery is 10% or below, a notification pops up. The warning displays a message and counts down from
 * 10, after which the robot will be released. User will be moved out of RG page.
 * Clicking outside the dialog will not close it. Warning closes only on Confirm or when countdown finishes.
 * @param {String} robotSerial - robot serial number
 * @param {Boolean} openParam - Boolean value to show warning or hide warning
 * @param {Function} onConfirm - Function to handle clicking Confirm button
 * @param {Function} onDisconnect - Function to handle disconnecting user from robot
 * @param {Number} batteryLevel - Robot's battery level
 * @param {Object} containerRef - Used to anchor component to its parent. Please see notes on PR #402 for more details.
 *
 */
const BatteryAlert = ({ robotSerial, openParam, onConfirm, onDisconnect, batteryLevel, containerRef }) => {
  const [countdown, setCountdown] = useState(10);
  const [warningFlash, setWarningFlash] = useState(false);
  const classes = useStyles();
  const isSimMode = robotSerial.includes('sim');

  const lowBatteryWarning = {
    title: 'Low Battery',
    message: `Please Drive ${robotSerial} Back to Charging Station`,
    icon: classes.warningIcon
  };

  const criticalBatteryWarning = {
    title: 'Critical Battery',
    message: `${robotSerial}'s Battery Level is Critical. Robot Will Now Be Disconnected`,
    icon: classes.errorIcon
  };
  /**
   * Timer to countdown disconnecting robot
   * Additionally used for effects, flashing warning sign.
   */
  useEffect(() => {
    let countdownInterval;

    if (batteryLevel && batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [batteryLevel]);

  /**
   * Flash warning sign effect.
   */
  useEffect(() => {
    let warningFlashInterval;

    if (batteryLevel && batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL) {
      warningFlashInterval = setInterval(() => {
        setWarningFlash((prevWarningFlash) => !prevWarningFlash);
      }, 1000);
    }

    return () => {
      if (warningFlashInterval) {
        clearInterval(warningFlashInterval);
      }
    };
  }, [batteryLevel]);

  useEffect(() => {
    if (countdown === 0 && !isSimMode) {
      onDisconnect();
    }
  }, [countdown, onDisconnect]);
  return (
    <Dialog open={openParam} className={classes.dialog} hideBackdrop container={containerRef.current} style={{ position: 'absolute' }}>
      {/* dialog content */}
      <DialogTitle className={classes.dialogTitle}>
        <WarningIcon
          style={{ opacity: warningFlash ? 0.5 : 1 }}
          className={`${classes.centeredIcon} ${
            batteryLevel <= BATTERIES_WARNING_LEVELS.LOW_LEVEL && batteryLevel > BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
              ? lowBatteryWarning.icon
              : batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
                ? criticalBatteryWarning.icon
                : ''
          }`}
        />
        <div>
          <h3>
            {batteryLevel <= BATTERIES_WARNING_LEVELS.LOW_LEVEL && batteryLevel > BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
              ? lowBatteryWarning.title
              : batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
                ? criticalBatteryWarning.title
                : ''}
          </h3>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p>
          {batteryLevel <= BATTERIES_WARNING_LEVELS.LOW_LEVEL && batteryLevel > BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
            ? lowBatteryWarning.message
            : batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL
              ? criticalBatteryWarning.message
              : ''}
        </p>
        {batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL ? <p>Disconnecting in {countdown} seconds...</p> : null}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {batteryLevel <= BATTERIES_WARNING_LEVELS.LOW_LEVEL && batteryLevel > BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL ? (
          <Button onClick={onConfirm} className={classes.confirmButton}>
            Confirm
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
export default BatteryAlert;
