import React, { useEffect, useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker } from 'react-google-maps';

/** If googleMapData is empty, polylines will not be rendered. Marker indicating robot position however will always be shown on map  */
function SolarMap({ googleMapData, robotLat, robotLng, robotHeading, selectedSubRows, resetRobot }) {
  const [progress, setProgress] = useState([]);
  const [dragged, setDragged] = useState(false); // state used to disable robot tracking after using dragging action
  const [center, setCenter] = useState({ lat: robotLat, lng: robotLng }); // Initialize center with robot's position

  useEffect(() => {
    if (googleMapData.length > 0) {
      setCenter({
        lat: Number(googleMapData[0][0]?.lat ?? robotLat),
        lng: Number(googleMapData[0][0]?.lng ?? robotLng)
      });
    } else {
      setCenter({ lat: robotLat, lng: robotLng });
    }
  }, [googleMapData, robotLat, robotLng]);

  useEffect(() => {
    if (robotLat && robotLat !== 0 && robotLng && robotLng !== 0) {
      setProgress((prevProgress) => [...prevProgress, { lat: robotLat, lng: robotLng }]);
      if (!dragged) {
        // Set the center of the Map to be equal the center of the Robot only if user is not controlling the map
        setCenter({ lat: robotLat, lng: robotLng });
      }
    }
  }, [robotLat, robotLng]);

  useEffect(() => {
    if (resetRobot) {
      setProgress([]);
    }
  }, [resetRobot]);

  const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  };

  const icon = (fillColor, rotation) => ({
    path: 'M 299.5,-0.5 C 299.833,-0.5 300.167,-0.5 300.5,-0.5C 400.455,198.41 500.122,397.41 599.5,596.5C 599.5,597.5 599.5,598.5 599.5,599.5C 598.833,599.5 598.167,599.5 597.5,599.5C 498.256,556.551 398.923,513.551 299.5,470.5C 200.481,513.719 101.481,556.719 2.5,599.5C 1.5,599.5 0.5,599.5 -0.5,599.5C -0.5,598.5 -0.5,597.5 -0.5,596.5C 99.2117,397.41 199.212,198.41 299.5,-0.5 Z',
    fillColor,
    fillOpacity: 1,
    scale: 0.0275,
    anchor: new window.google.maps.Point(300, 300),
    rotation
  });

  return (
    <GoogleMap
      defaultZoom={18}
      center={center}
      mapTypeId="satellite"
      tilt={0}
      options={mapOptions}
      onDragStart={() => {
        // Disable robot following and reset center to return to the normal behaviour
        setDragged(true);
      }}
      onDragEnd={() => {
        // Enable robot following and reset center to return to the normal behaviour
        setDragged(false);
      }}
    >
      <>
        {googleMapData.length > 0 &&
          googleMapData.map((line, index) => {
            const isCurrentSubRow = line?.[0]?.key && selectedSubRows?.[0] && line?.[0]?.key === selectedSubRows?.[0];
            const isSelectedSubRow = selectedSubRows?.length > 0 && line?.[0]?.key && selectedSubRows.includes(line?.[0]?.key);
            const strokeColor = isCurrentSubRow ? '#00FF00' : '#00FFFF';
            const transparency = isSelectedSubRow ? 1 : 0;
            const rotation = 90 - (line[0].angle * 180) / Math.PI;
            return (
              <React.Fragment key={index}>
                <Polyline path={line} options={{ strokeColor }} />

                <Marker icon={icon('yellow', rotation)} position={line[0]} opacity={transparency} />
              </React.Fragment>
            );
          })}

        {progress.length > 0 && <Polyline path={progress} options={{ strokeColor: '#FF0000', zIndex: 10 }} />}

        <Marker
          icon={icon('#FF0000', 90 - (robotHeading * 180) / Math.PI)}
          position={progress[progress.length - 1] || { lat: robotLat, lng: robotLng }}
        />
      </>
    </GoogleMap>
  );
}

const MapComponent = withScriptjs(withGoogleMap(SolarMap));
const MapComponentExport = ({
  googleMapData,
  robotLat,
  robotLng,
  robotHeading,
  selectedSubRows,
  resetRobot,
  customStyle,
  width = '100%',
  height
}) => (
  <MapComponent
    googleMapData={googleMapData}
    robotLat={robotLat}
    robotLng={robotLng}
    robotHeading={robotHeading}
    selectedSubRows={selectedSubRows}
    resetRobot={resetRobot}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ MarginTop: '20px', height, width }} className={customStyle} />}
    mapElement={<div style={{ height: '100%' }} />}
  />
);

export default MapComponentExport;
