import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl, Grid, makeStyles, MenuItem, Select, Typography, Button, Fade } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStores } from '../../store/root/root.store';
// import LocationsTable from '../components/tables/locations.table';
import LocationsTable, {
  LOCATION_REGION,
  LOCATION_PROPERTY,
  LOCATION_SECTION,
  LOCATION_BLOCK,
  LOCATION_SUBBLOCK
  // LOCATION_SUBSECTION
} from '../../components/tables/LocationsTable';
import { ROUTE_ADMIN_NEW_LOCATION } from '../routes';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250,
    marginTop: theme.spacing(4)
  },
  tabelLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  teachButton: {
    marginTop: theme.spacing(4),
    marginDown: theme.spacing(0),
    height: 80,
    width: 200,
    backgroundColor: '##0000FF',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  }
}));

export const ManageLocationsPage = observer(() => {
  const { regionsStore, sectionStore, chaperonePropertyStore, countriesStore, blocksStore, subBlocksStore } = useStores();
  const classes = useStyles();

  const { selectedCountryId } = countriesStore;
  const { selectedRegionId } = regionsStore;
  const { selectedPropertyId } = chaperonePropertyStore;
  const { selectedBlockId } = blocksStore;

  useEffect(() => {
    async function init() {
      countriesStore.clearSelectedCountry();
      regionsStore.clearRegions();
      regionsStore.clearSelectedRegion();
      chaperonePropertyStore.clearProperties();
      chaperonePropertyStore.setSelectedProperty(null);
      sectionStore.clearSections();
      sectionStore.setSelectedSection(null);
      blocksStore.clearBlocks();
      blocksStore.setSelectedBlock(null);
      subBlocksStore.clearSubBlocks();
      subBlocksStore.setSelectedSubBlock(null);
      await countriesStore.getCountries();
    }
    init();
  }, []);

  useEffect(() => {
    async function getRegionsByCountry() {
      if (Number(selectedCountryId)) {
        await regionsStore.getRegions(selectedCountryId);
      } else {
        regionsStore.clearRegions();
        chaperonePropertyStore.clearProperties();
        sectionStore.clearSections();
      }
    }
    getRegionsByCountry();
  }, [selectedCountryId]);

  useEffect(() => {
    async function getRegions() {
      if (Number(selectedRegionId)) {
        await chaperonePropertyStore.getProperties(selectedRegionId);
      } else {
        chaperonePropertyStore.clearProperties();
        sectionStore.clearSections();
      }
    }
    getRegions();
  }, [selectedRegionId]);

  useEffect(() => {
    async function getSections() {
      if (Number(selectedPropertyId)) {
        await sectionStore.getSections(selectedRegionId, selectedPropertyId);
        await blocksStore.getBlocks(selectedPropertyId);
      } else {
        sectionStore.clearSections();
        blocksStore.clearBlocks();
      }
    }
    getSections();
  }, [selectedPropertyId]);

  useEffect(() => {
    async function getSubBlocks() {
      if (Number(selectedBlockId)) {
        await subBlocksStore.getSubBlocks(selectedBlockId);
      } else {
        subBlocksStore.clearSubBlocks();
      }
    }
    getSubBlocks();
  }, [selectedBlockId]);

  return (
    <Fade in>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        {/* locations */}
        <Grid item xs={3}>
          <Grid item>
            <Typography variant="h3" className={classes.titleLable}>
              Manage Locations
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            <Button className={classes.teachButton} variant="contained" component={Link} to={ROUTE_ADMIN_NEW_LOCATION} color="secondary">
              Add Location
            </Button>
          </Grid>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <FormControl className={classes.formControl}>
            <Typography variant="h4" className={classes.tabelLable}>
              Country:
            </Typography>
            <Select
              className={classes.select}
              value={countriesStore.getSelectedCountry().id || 'NONE'}
              onClick={(e) => {
                if (e.target.value && selectedCountryId !== e.target.value) {
                  countriesStore.setSelectedCountry(Number(e.target.value) || null);
                  regionsStore.setSelectedRegion(null);
                  chaperonePropertyStore.setSelectedProperty(null);
                  sectionStore.setSelectedSection(null);
                }
              }}
            >
              <MenuItem key="NONE" value="NONE">
                <em>NONE</em>
              </MenuItem>
              {countriesStore.countries.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Regions */}
        {selectedCountryId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.titleLable}>
                Regions:
              </Typography>
            </Grid>
            <LocationsTable locationType={LOCATION_REGION} />
          </Grid>
        )}

        {/* Properties */}
        {selectedRegionId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.titleLable}>
                Properties:
              </Typography>
            </Grid>
            <LocationsTable locationType={LOCATION_PROPERTY} />
          </Grid>
        )}

        {/* Sections & Blocks */}
        {selectedPropertyId && (
          <>
            <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.titleLable}>
                  Snow Sections:
                </Typography>
              </Grid>
              <LocationsTable locationType={LOCATION_SECTION} />
            </Grid>
            <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.titleLable}>
                  Solar Blocks:
                </Typography>
              </Grid>
              <LocationsTable locationType={LOCATION_BLOCK} />
            </Grid>
          </>
        )}
        {/* Sub Blocks */}
        {selectedBlockId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.titleLable}>
                Sub Blocks:
              </Typography>
            </Grid>
            <LocationsTable locationType={LOCATION_SUBBLOCK} />
          </Grid>
        )}
      </Grid>
    </Fade>
  );
});
