import { types } from 'mobx-state-tree';
import { Motor, ConnectedClient } from '../resources/models/autonomy-robot.model';
import { LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD, GAMMA, CONTRAST } from '../../services/chaperone/robot-connection.service';

export const Robot = types.model({
  id: types.number,
  serialNumber: types.identifier,
  name: types.string,
  batteryLevel: types.maybeNull(types.number),
  siteName: types.maybeNull(types.string),
  streamURL: types.maybeNull(types.string),
  currentRow: types.maybeNull(types.integer),
  currentRowName: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  operatingSubsectionId: types.maybeNull(types.integer),
  useCase: types.string,
  motors: types.map(Motor, {}),
  isDoneRepeating: types.maybeNull(types.boolean),
  isTeleopsStreamingEnabled: types.optional(types.boolean, false),
  isDoneRepeatingMission: types.maybeNull(types.boolean),
  isDoneRepeatingPath: types.maybeNull(types.boolean),
  streamToken: types.maybeNull(types.string),
  selectedSolarSubRows: types.array(types.string),
  robotStateStamp: 0,
  lastRobotStateReceived: 0,
  previousWpsState: 0,
  wpsState: types.optional(types.number, -1),
  controlMode: 'manual',
  plowControlMode: 'manual',
  salterControlMode: 'manual',
  previousControlMode: 'manual',
  lightsState: false,
  beeperState: false,
  fanState: false,
  wiperState: false,
  estopState: false,
  sensingEdgeState: false,
  swEstopState: false,
  lockoutState: '',
  speedLimit: 0,
  plowState: 0,
  plowHeight: 0,
  notificationMessage: '',
  isLawnMowing: false,
  isFirstLocation: true,
  isFirstHeading: true,
  stableLocalization: false,
  firstLng: types.optional(types.number, 0),
  firstLat: types.optional(types.number, 0),
  lng: types.optional(types.number, 0),
  lat: types.optional(types.number, 0),
  firstHeading: 0.0,
  gpsFixStatus: 'RTK Fixed',
  currentHeadingRad: 0.0,
  numberOfBatteries: 0,
  portalClientCount: 0,
  linearSpeed: LINEAR_SPEED.default,
  lookAheadDistance: LOOK_AHEAD.default,
  angularSpeed: ANGULAR_SPEED.default,
  videoGamma: GAMMA.default,
  videoContrast: CONTRAST.default,
  latency: 0,
  highLatency: false,
  safetyEnabled: true,
  safetyErrorMessage: '',
  attachmentType: '',
  deckOffset: 0,
  frameOffset: 0,
  autonomyDeviationDistance: types.optional(types.number, -1),
  autonomyDeviationHeading: types.optional(types.number, -1),
  deviationPositionStopThreshold: types.optional(types.number, 0),
  bladeRunning: types.maybe(types.boolean),
  auotnomyStoppedDeviated: types.maybe(types.boolean, false),
  activeClient: types.maybeNull(ConnectedClient),
  linearVelocity: 0,
  isRightOffsetDeck: types.maybeNull(types.boolean),
  isLowStreamingEnabled: types.optional(types.boolean, false),
  deviationPositionSlowSpeed: types.optional(types.number, 0),
  deviationPositionSlowThreshold: types.optional(types.number, 0),
  deviationHeadingStopThreshold: types.optional(types.number, 0),
  deviationHeadingSlowThreshold: types.optional(types.number, 0),
  autonomyNavigationDeviationCheck: types.optional(types.number, 0),
  enableInterRowHopping: types.optional(types.boolean, false),
  enableDevBot: types.optional(types.boolean, false),
  acceptableZedLevel: types.optional(types.number, 0),
  enableAutonomousAttachmentControl: types.optional(types.number, 0),
  enableAutonomousBladesDuringAutonomy: types.optional(types.boolean, false),
  enableManualBladesDuringAutonomy: types.optional(types.boolean, false),
  hoseCoolingEnabled: types.optional(types.boolean, false),
  autonomyAttachmentController: types.optional(types.string, ''),
  autonomyNavigationMode: types.optional(types.string, ''),
  autonomyNavigationController: types.optional(types.string, ''),
  currentUseCase: types.optional(types.string, ''),
  pathType: types.optional(types.string, ''),
  interRowDirection: types.optional(types.string, '')
});

export const TeleopsMode = types.model('TeleOpsMode', {
  serialNumber: types.optional(types.string, ''),
  status: types.optional(types.boolean, false)
});
