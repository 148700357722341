import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import redEStop from '../../assets/images/red_e-stop_engaged.png';
import greyEStop from '../../assets/images/grey_e-stop_engaged.png';
import '../../App.css';

export const EStopIndicator = ({
  eStopEngaged,
  videoStream,
  width,
  height,
  marginLeft,
  marginTop,
  marginRight,
  marginBottom,
  isMultiRobots,
  isMultiRobotAudit
}) => {
  const useStyles = makeStyles((theme) => ({
    image: {
      position: 'relative',
      bottom: '0px',
      width,
      height,
      marginLeft,
      marginTop,
      marginRight,
      marginBottom
    },
    multiRobotsEStopIndicator: {
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    multiRobotsAuditEStopIndicator: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    multiRobotsEstopContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const classes = useStyles();

  if (videoStream) {
    if (eStopEngaged) {
      if (isMultiRobots && !isMultiRobotAudit) {
        return (
          <Box className={classes.multiRobotsEstopContainer} id="estop">
            <img src={redEStop} alt="E-Stop Sign" className={classes.multiRobotsEStopIndicator} />
          </Box>
        );
      } else if (isMultiRobots && isMultiRobotAudit) {
        return (
          <Box className={classes.multiRobotsEstopContainer} id="estop">
            <img src={redEStop} alt="E-Stop Sign" className={classes.multiRobotsAuditEStopIndicator} />
          </Box>
        );
      } else {
        return (
          <div id="estop">
            <img src={redEStop} className={classes.image} style={{ top: '200px', position: 'fixed', left: '33%' }} />
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  } else {
    useEffect(() => {
      var image = document.getElementById('E-Stop');

      if (eStopEngaged) {
        image.src = redEStop;
      } else {
        image.src = greyEStop;
      }
    });

    return (
      <div>
        <img src={redEStop} id="E-Stop" className={classes.image} />
      </div>
    );
  }
};
