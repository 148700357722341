import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import RobotIcon from '../../assets/images/robot.png';
import { INFRACTION_DIRECTIONS, ZONES } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  metricsContainer: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '.25rem',
    // padding: '1% 2%',
    // margin: '1% 2%',
    // fontWeight: 'bold',
    height: 430,
    width: 800,
    position: 'relative'
  },
  button: {
    background: `${theme.palette.secondary.main}4D`,
    border: `1px solid${theme.palette.secondary.main}`,
    color: theme.palette.secondary.contrastText,
    width: 50,
    height: 64,
    borderRadius: 32,
    position: 'absolute',
    fontSize: '250%'
  },
  // (e.g. 0=front, 1=right, 2=rear, 3=left)
  front0: { right: 75, top: 183 },
  front1: { right: 5, top: 183, backgroundColor: 'rgba(204, 167, 0)' },
  right0: { right: 380, bottom: 75 },
  right1: { right: 380, bottom: 5, backgroundColor: 'rgba(204, 167, 0)' },
  rear0: { left: 75, top: 183 },
  rear1: { left: 5, top: 183, backgroundColor: 'rgba(204, 167, 0)' },
  left0: { right: 380, top: 75 },
  left1: { right: 380, top: 5, backgroundColor: 'rgba(204, 167, 0)' },
  robot: { position: 'absolute', top: 125, left: 200 }
}));

function SafetyMetrics({ metrics, showInfractionsTable }) {
  const styles = useStyles();

  return (
    <div className={styles.metricsContainer}>
      <img alt="Plow state" className={styles.robot} src={RobotIcon} height={200} width={400} />

      <Button
        variant="outlined"
        className={[styles.button, styles.front0].join(' ')}
        onClick={() => showInfractionsTable(ZONES.ONE, INFRACTION_DIRECTIONS.FRONT)}
      >
        {metrics?.infraction_zone_1_direction_front}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.front1].join(' ')}
        onClick={() => showInfractionsTable(ZONES.TWO, INFRACTION_DIRECTIONS.FRONT)}
      >
        {metrics?.infraction_zone_2_direction_front}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.right0].join(' ')}
        onClick={() => showInfractionsTable(ZONES.ONE, INFRACTION_DIRECTIONS.RIGHT)}
      >
        {metrics?.infraction_zone_1_direction_right}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.right1].join(' ')}
        onClick={() => showInfractionsTable(ZONES.TWO, INFRACTION_DIRECTIONS.RIGHT)}
      >
        {metrics?.infraction_zone_2_direction_right}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.rear0].join(' ')}
        onClick={() => showInfractionsTable(ZONES.ONE, INFRACTION_DIRECTIONS.REAR)}
      >
        {metrics?.infraction_zone_1_direction_rear}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.rear1].join(' ')}
        onClick={() => showInfractionsTable(ZONES.TWO, INFRACTION_DIRECTIONS.REAR)}
      >
        {metrics?.infraction_zone_2_direction_rear}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.left0].join(' ')}
        onClick={() => showInfractionsTable(ZONES.ONE, INFRACTION_DIRECTIONS.LEFT)}
      >
        {metrics?.infraction_zone_1_direction_left}
      </Button>
      <Button
        variant="outlined"
        className={[styles.button, styles.left1].join(' ')}
        onClick={() => showInfractionsTable(ZONES.TWO, INFRACTION_DIRECTIONS.LEFT)}
      >
        {metrics?.infraction_zone_2_direction_left}
      </Button>
    </div>
  );
}

export default SafetyMetrics;
