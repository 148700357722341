import { robotStatusCustomOrder } from './constants';

export const sortTable = (data, sortParam, sortType) => {
  const copyData = [...data];
  let sortableData = copyData;
  const isDate = sortParam.includes('date');
  const isNumber = sortParam.includes('numeric');

  if (!isDate) {
    if (isNumber) {
      const sortParamWithoutPrefix = sortParam.split(':')?.[1];
      if (sortType === 'asc') {
        sortableData = copyData.sort((a, b) => a[sortParamWithoutPrefix] - b[sortParamWithoutPrefix]);
      } else {
        sortableData = copyData.sort((a, b) => b[sortParamWithoutPrefix] - a[sortParamWithoutPrefix]);
      }
    } else if (sortParam === 'status') {
      if (sortType === 'asc') {
        sortableData = copyData.sort((a, b) => robotStatusCustomOrder[a[sortParam]] - robotStatusCustomOrder[b[sortParam]]);
      } else {
        sortableData = copyData.sort((a, b) => robotStatusCustomOrder[b[sortParam]] - robotStatusCustomOrder[a[sortParam]]);
      }
    } else {
      sortType === 'asc'
        ? (sortableData = copyData.sort((a, b) => a[sortParam]?.localeCompare(b[sortParam])))
        : (sortableData = copyData.sort((a, b) => -1 * a[sortParam]?.localeCompare(b[sortParam])));
    }
  } else {
    const sortParamWithoutPrefix = sortParam.split(':')?.[1];
    if (sortType === 'asc') {
      sortableData = copyData.sort((a, b) => new Date(a[sortParamWithoutPrefix]) - new Date(b[sortParamWithoutPrefix]));
    } else {
      sortableData = copyData.sort((a, b) => new Date(b[sortParamWithoutPrefix]) - new Date(a[sortParamWithoutPrefix]));
    }
  }
  return sortableData;
};
