import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';

const ConfirmActionDialog = ({ actionDisabled=false, handleAction2=null, handleAction, handleClose, title, body, open, action2 = null, action = 'Delete', containerRef, backdropProps }) => (
  <Dialog
    open={open}
    container={containerRef === undefined || !containerRef ? null : containerRef.current}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth={'xs'}
    style={{ position: 'absolute' }}
    BackdropProps={backdropProps}
  >
    <DialogTitle onClose={handleClose} id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <Typography gutterBottom>{body}</Typography>
    </DialogContent>
    <DialogActions>
      {action2 && (
        <Button onClick={handleAction2} variant="contained" style={{ backgroundColor: '#466076', color: 'white' }}>
        {action2}
      </Button>
      )}
      <Button onClick={handleClose} variant="outlined" color="primary">
        Cancel
      </Button>
      <Button onClick={handleAction} variant="contained" disabled={actionDisabled} color="secondary">
        <b>{action}</b>
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmActionDialog;
